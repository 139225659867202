<template>
  <div id="background">
    <div class="title">
      <transition name="el-fade-in">
        <h3 align="center" v-show="title_center">背景更换</h3>
      </transition>
      <i class="el-icon-close close" @click="close"></i>
    </div>
    <div class="main" @scroll="scroll">
      <div style="height: 43px">
        <transition name="el-fade-in">
          <h1 v-show="title_left">背景更换</h1>
        </transition>
      </div>
      <br />
      <h4>自定义</h4>
      <div style="width: 455px">
        <el-card shadow="hover">
          <p style="margin-bottom: 10px">本地图片(小于4M)</p>


          <!-- <div class="upload_img">
            <div
              class="choiceness"
              :style="{
                backgroundImage: option == 2 && 'url(' + this.$store.state.wallpaperurl,
              }"
            >
              <div class="img_shelter" v-show="option == 2">
                <i class="el-icon-success"></i>
              </div>
            </div>
            <div class="upload_img_right">
              <h5>将你喜欢的图片设为壁纸</h5>
              <p>建议图片大小1080P或更高</p>
              <input
                type="file"
                accept="image/png, image/jpeg, image/gif, image/jpg"
                name="file"
                id="file"
                style="opacity: 0; height: 0px"
                @change="ddd($event)"
              />
              <label for="file" style="width: 105px"
                ><div class="btu">
                  <h6>上传图片</h6>
                </div>
              </label>
            </div>
          </div> -->

          <p style="margin: 10px 0 10px 0">使用网络图片</p>
          <div>
            <div class="upload_img">
              <div class="choiceness" :style="{backgroundImage: 'url(' + network_img + ')' }"
                @click="changeBackground(network_img)">
                <div class="img_shelter" v-show="current_img == network_img">
                  <i class="el-icon-success"></i>
                </div>
              </div>
              <div class="upload_img_right">
                <h5>推荐使用网络图片</h5>
              </div>
            </div>
            <el-input placeholder="URL" v-model="input" clearable style="margin-top: 10px">
              <el-button slot="append" icon="el-icon-thumb" @click="inter_img(input)"></el-button>
            </el-input>
          </div>
        </el-card>
      </div>
      <h4>精选壁纸</h4>
      <div style="width: 455px">
        <el-card shadow="hover">
          <div class="choiceness-img">
            <div v-for="(item, index) in url" :key="index" class="choiceness"
              :style="{ backgroundImage: 'url(' + item + ')' }" @click="changeBackground(item)">
              <div class="img_shelter" v-show="current_img == item">
                <i class="el-icon-success"></i>
              </div>
            </div>
            <p>壁纸来源：ZOL壁纸</p>
          </div>
        </el-card>
      </div>
      <h4>必应</h4>
      <div style="width: 455px">
        <el-card shadow="hover">
          <div class="biyin-img">
            <div class="choiceness"></div>
            <div style="margin-left: 20px; width: 290px; height: 70px">
              <h5>同步必应壁纸，每日更新</h5>
              <p>今日壁纸：{{}}</p>
              <p>图片来源：必应</p>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value:'',
      current_img: this.$store.state.wallpaperurl,
      local_img:this.$store.state.local_img,
      // option: this.$store.state.wallpaperoption,
      title_center: false,
      title_left: true,
      url: [
        "https://desk-fd.zol-img.com.cn/t_s2560x1600c5/g6/M00/00/0A/ChMkKmF7UaOIRl6oAA1ayxiSOiUAAVDPgCm160ADVrj677.jpg",
        "https://desk-fd.zol-img.com.cn/t_s2560x1600c5/g6/M00/0C/0B/ChMkKmFxJv6ITvt-ADrIZrRnFvwAAUzZwG4g38AOsh-256.jpg",
        "https://desk-fd.zol-img.com.cn/t_s2560x1600c5/g5/M00/08/04/ChMkJleF6LqII-GfABbNXaNy1TgAATeOgGDb_UAFs11717.jpg",
        "https://desk-fd.zol-img.com.cn/t_s2560x1600c5/g2/M00/0D/0C/ChMlWl7WGpaIYfP2AATsrNFrYpMAAPsKwFJH0sABOzE303.jpg",
        "https://desk-fd.zol-img.com.cn/t_s2560x1600c5/g6/M00/05/08/ChMkKWCZ6bmIExkDACD41vUVTtIAAOoPAHKjAwAIPju203.jpg",
        "https://desk-fd.zol-img.com.cn/t_s2560x1600c5/g5/M00/01/04/ChMkJ1dyFd-IEkmXAAfK6Cb1rngAATCUwDi36QAB8sA526.jpg",
      ],
    };
  },
  methods: {
    //选择本地图片
    ddd(e) {
      let reads = new FileReader();
      let file = e.target.files[0];
      // let data;
      if (file.size >= 4000000) {
        this.$message.error("文件大于4M，请重新选择文件！");
      } else {
        let data = this.$store;
        reads.readAsDataURL(file);
        reads.onload = function () {
          console.log(this);
          data.commit("setWallpaperurl", this.result);
          data.commit("setWallpaperoption", 0);
        };
      }
    },
    //选择精选图片
    changeBackground(url) {
      this.$store.commit("setWallpaperurl", url);
      this.current_img = url;
      this.$store.commit("setWallpaperoption", 0);
    },
    //使用网络图片
    inter_img(url) {
      if (url != undefined) {
        console.log(url);
        this.$store.commit("setWallpaperurl", url);
        this.$store.commit("setNotwork_img",url)
        // this.current_img = url;
        this.$store.commit("setWallpaperoption", 1);
      } else {
        this.$message.error("请输入正确网址！");
      }
    },

    close() {
      this.$emit("setting-close");
    },
    scroll(event) {
      if (event.target.scrollTop < 40) {
        this.title_center = false;
        this.title_left = true;
        // console.log("11");
      } else {
        this.title_center = true;
        this.title_left = false;
        // console.log("22");
      }
    },
  },
  computed: {
    input: {
      get: function () {
        return this.$store.state.notwork_im;
      },
      set: function (value) {
        this.value = value;
      },
    },
   network_img:{
     get: function(){
       console.log(this.$store.state.notwork_img)
      return this.$store.state.notwork_img
     }
   }
   
   
   
  },
};
</script>
<style scoped>
.img_shelter {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #67c23a;
  background: rgba(0, 0, 0, 0.2);
}
.btu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 104px;
  height: 40px;
  background: #409eff;
  border-radius: 25px;
  margin-left: 20px;
}
.btu h6 {
  text-align: center;
}
.choiceness {
  width: 120px;
  height: 70px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120px 70px;
  border-radius: 15px;
  cursor: pointer;
  overflow: auto;
  margin-bottom: 10px;
}
.choiceness:hover {
  animation: img_up 0.2s;
  animation-fill-mode: forwards;
}
.choiceness-img {
  height: 160px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.upload_img {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload_img_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 120px;
  height: 80px;
  background: #f2f2f2;
  border-radius: 15px;
}
.upload_img_right {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upload_img_left i {
  font-size: 40px;
}
.biyin-img {
  display: flex;
  flex-direction: row;
}
#background {
  width: 490px;
  height: 570px;
  background: #f5f5f5;
  border-radius: 25px;
}
.main {
  padding-left: 15px;
  overflow: auto;
  width: 470px;
  height: 500px;
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.close {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  font-size: 25px;
  position: absolute;
  right: 20px;
}
h4 {
  margin-bottom: 10px;
  margin-top: 10px;
}
p {
  color: #949494;
  font-size: 12px;
}
.close:hover {
  color: #e10b2b;
  background: rgba(222, 222, 222, 0.6);
}
.main::-webkit-scrollbar {
  /*滚动条整体样式*/

  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
}

.main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/

  border-radius: 10px;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  background: #535353;
}

.main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  border-radius: 10px;

  /* background: #dedede; */
}
@keyframes img_up {
  0% {
  }
  100% {
    background-size: 150px 100px;
  }
}
</style>