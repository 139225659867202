<template>
  <div id="feedback">
    <div class="title">
       <transition name="el-fade-in">
        <h3 align="center" v-show="title_center">反馈</h3>
      </transition>
      <i class="el-icon-close close" @click="close"></i>
    </div>
    <div class="main" @scroll="scroll">
      <div style="height: 43px">
        <transition name="el-fade-in">
          <h1 v-show="title_left">反馈</h1>
        </transition>
      </div>
      <br />
      <h4>BUG反馈/建议</h4>
      <div style="width: 455px">
        <el-card shadow="always">
          <p>
            开发过程中难免疏漏，如发现bug或影响使用的地方，当然如果你有什么好的建议、点子。都可以发送邮件到
            <strong>xhfsky520@163.com</strong>。<br />
            <strong>我会在第一时间处理！</strong>
          </p>
        </el-card>
      </div>
      <h4>更新计划</h4>
      <div style="width: 455px">
        <el-card shadow="always"> </el-card>
      </div>
      <h4>感谢</h4>
      <div style="width: 455px">
        <el-card shadow="always"> </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
       title_center: false,
      title_left: true,
    };
  },
  methods: {
    close() {
      this.$emit("setting-close");
    },
    scroll(event) {
      if (event.target.scrollTop < 40) {
        this.title_center = false;
        this.title_left = true;
        console.log("11");
      } else {
        this.title_center = true;
        this.title_left = false;
        console.log("22");
      }
    },
  },
};
</script>
<style scoped>
#feedback {
  width: 490px;
  height: 570px;
  background: #f5f5f5;
  border-radius: 25px;
}
.main {
  padding-left: 15px;
  overflow: auto;
  width: 470px;
  height: 500px;
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.close {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  font-size: 25px;
  position: absolute;
  right: 20px;
}
h4 {
  margin-bottom: 10px;
  margin-top: 10px;
}
p {
  color: #949494;
  font-size: 12px;
}
.close:hover {
  color: #e10b2b;
  background: rgba(222, 222, 222, 0.6);
}
.main::-webkit-scrollbar {
  /*滚动条整体样式*/

  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
}

.main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/

  border-radius: 10px;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  background: #535353;
}

.main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  border-radius: 10px;

  /* background: #dedede; */
}
</style>