import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueAxios from 'vue-axios'
import Animation from 'animate.css'
import "./assets/iconfont/iconfont.js"
import 'element-ui/lib/theme-chalk/index.css';

import Util from './assets/js/util.js'
import store from './store'
Vue.config.productionTip = false
//  Vue.prototype.Store = Store
Vue.prototype.Util = Util
Vue.use(VueAxios, axios)
Vue.use(ElementUI)
Vue.use(Animation)
new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
