<template>
  <div id="input">
    <!-- 自定义输入款 -->
    <div class="input-box" :class="{ border: bor }">
      <svg class="icon" aria-hidden="true">
        <use :xlink:href="icon"></use>
      </svg>
      <input
        type="text"
        :placeholder="placeholder"
        class="input"
        @focus="focus"
        @keyup.enter="search"
        v-model="value"
        @input="predictions && input()"
        @blur="blur"
      />
    </div>
    <!-- 搜索预测框展示动画 -->
    <el-collapse-transition>
      <div class="predictions" v-show="show">
        <a
          class="list"
          v-for="list in sugrec_list"
          :key="list.sa"
          :href="seurl + list.q"
          target="blank"
        >
          {{ list.q }}
        </a>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sugrec_list: [], //搜索预测结果列表
      last_value: "", //输入框上一次结果（与输入框中内容做判断）
      value: "", //输入框内容
      show: false, //搜索预测展示
      timer1: 0, //定时器编号1
      timer2: 0, //定时器编号2
      bor: false, //边框显示
      // icon: "", //搜索引擎图标
      seurl: "",
    };
  },

  // ---------------
  props: {
    se: String, //搜索引擎
    placeholder: String, //占位符
    predictions: Boolean, //搜索预测
    history: Boolean, //搜索历史
  },


  methods: {
    //发起搜索
    search() {
      window.location.href = this.seurl + this.value;
    },
    //边框展示
    blur() {
      this.bor = false;
    },
    //输入框获得焦点时
    focus() {
      this.bor = true;
      clearTimeout(this.timer2);
      this.timer2 = setTimeout(() => {
        if (this.value != "") {
          this.show = true;
        } else {
          this.show = false;
        }
      }, 300);
      console.log(this.timer2);
    },
    //当输入框有输入时
    input() {
      if (this.value != this.last_value) {
        // console.log(this.value);
        //防抖
        clearTimeout(this.timer1);
        this.timer1 = setTimeout(() => {
          if (this.value != "") {
            this.$emit("input-focus");
            this.axios
              .get("http://112.74.104.232:8080/homepage/forecast", {
                params: {
                  wd: this.value,
                },
              })
              .then((response) => {
                this.sugrec_list = response.data.g;
                this.show = true;
                console.log(response.data.g);
              });
          } else {
            //向组件外抛出自定义事件
            this.$emit("input-null");
            this.show = false;
          }
        }, 300);
        // ------------------
        // console.log(this.timer1);

        this.last_value = this.value;
      } else {
        this.last_value = this.value;
      }
    },
  },
  computed: {
     icon() {
    if (this.se == "baidu") {
      this.seurl = "https://www.baidu.com/s?ie=UTF-8&wd=";
      return "#icon-baidu"
    } else if (this.se == "google") {
      this.seurl = "https://www.google.com/search?q=";
      return "#icon-gugesousuo"
    } else if (this.se == "biying") {
      this.seurl = "https://cn.bing.com/search?q=";
      return "#icon-biying"
    } else if (this.se == "360") {
      this.seurl = "https://www.so.com/s?ie=utf-8&q=";
      return "#icon-sousuo"
    } else if (this.se == "sougou") {
      this.seurl = "https://www.sogou.com/web?query=";
      return "#icon-sougousousuo"
    } else {
      this.seurl = "https://www.baidu.com/s?ie=UTF-8&wd=";
      return "#icon-baidu"
    }},
  } 
};
</script>

<style scoped>
a {
  text-decoration: none;
}
/* input:-moz-placeholder {
  color: #f5f5f5;
}

input::-moz-placeholder {
color: #f5f5f5;
}

input:-ms-input-placeholder {
  color: #f5f5f5;
}

input::-webkit-input-placeholder {
 color: #f5f5f5;
} */
#input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon {
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 30px;
}
.input-box {
  width: 600px;
  height: 40px;
  border: 2px solid;
  background: rgba(256, 256, 256, 0.6);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.border {
  border: 2px solid cornflowerblue;
  background: #ffffff;
}
.input {
  width: 550px;
  height: 37px;
  border: 0px;
  border-radius: 20px;
  outline: none;
  background: rgba(0, 0, 0, 0);
}
.predictions {
  width: 600px;
  /* height: 300px; */
  display: flex;
  overflow: auto;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 20px;
  background: rgba(256, 256, 256, 0.3);
}
.list {
  width: 600px;
  height: 30px;
  line-height: 30px;
  text-indent: 2em;
  color: white;
}
.list:hover {
  animation: frame 0.3s;
  animation-fill-mode: forwards;
  background: #d1d1d1;
  color: cornflowerblue;
}
/* 预测条目动画 */
@keyframes frame {
  0% {
  }
  100% {
    text-indent: 3em;
  }
}
</style>