<template>
  <div id="date">
    <!-- 左边 -->
    <div class="left">
      <!-- 时间 -->
      <div class="time">{{ time }}</div>
      <!-- 日期 -->
      <div class="date">{{ date }}</div>
      <!-- 地区 -->
      <el-tooltip
        class="item"
        effect="dark"
        content="点击更改地区"
        placement="bottom"
      >
        <!-- 弹出内容 -->
        <el-popover placement="bottom" width="160" trigger="click">
          <div style="text-align: center; margin: 0">
            <el-switch
              @change="autoLocation"
              v-model="auto"
              inactive-text="自动获取位置"
            >
            </el-switch>
            <div class="demo-input-suffix" style="margin-top: 12px">
              <el-autocomplete
                class="inline-input"
                :fetch-suggestions="querySearch"
                :trigger-on-focus="false"
                placeholder="搜索城市"
                prefix-icon="el-icon-search"
                v-model="input2"
                @select="handleSelect"
                :disabled="auto"
              >
              </el-autocomplete>
            </div>
          </div>
          <div class="area" slot="reference">
            {{ area }}
            <i class="el-icon-location-outline"></i>
          </div>
        </el-popover>
      </el-tooltip>
    </div>
    <!-- 右边 -->
    <div class="right">
      <!-- 分割线 -->
      <div class="line"></div>
      <!-- 天气 -->
      <el-popover placement="right" width="100" trigger="hover" title="详情">
        <!-- 弹出内容 -->
        <div style="display: flex; flex-direction: row">
          <div>
            <p>天气：{{ weathername }}</p>
            <p>温度：{{ temperature }}℃</p>
            <p>风向：{{ winddirection }}</p>
            <p>风力：{{ windpower }}级</p>
            <p>湿度：{{ humidity }}%</p>
          </div>
          <div style="margin-left: 20px">
            <div class="img">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="weather"></use>
              </svg>
            </div>
          </div>
        </div>
        <p style="font-size: 8px; color: #dedede; margin-top: 5px">
          更新于：<br />
          {{ reporttime }}<br />
          天气信息来自<a
            href="https://lbs.amap.com"
            target="blank"
            style="text-decoration: none"
            >高德开放平台</a
          >
        </p>

        <div class="weather" slot="reference">
          <!-- 天气图标 -->
          <div class="img">
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="weather"></use>
            </svg>
          </div>
          <div class="weathername">
            <p>{{ weathername }}</p>
          </div>
          <!-- 温度 -->
          <div class="temperature">{{ temperature }}℃</div>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { weather } from "../assets/js/weather.js";
export default {
  data() {
    return {
      msec: 0, //时间毫秒
      areaCode: "", //地区代码
      area: "", //地区名称
      input2: "", //输入框内容
      areaCodeList: "", //地区列表
      temperature: "", //温度
      weather: "#icon-100-qing", //天气图标
      weathername: "晴", //天气名称
      winddirection: "", //风向
      windpower: "", //风力
      humidity: "", //湿度
      reporttime: "", //发布时间
    };
  },

  props: {
    auto: Boolean,//自动获取位置
  },

  methods: {
    autoLocation(i) {
      this.$store.commit("setAuto_location" , i);
      if (i== true) {
        this.axios
          .get("http://112.74.104.232:8080/homepage/area")
          .then((response) => {
            if (response.data.regionCode != "0") {
              this.areaCode = response.data.regionCode;
              this.area = response.data.region;
            } else if (response.data.cityCode != "0") {
              this.areaCode = response.data.cityCode;
              this.area = response.data.city;
            } else if (response.data.proCode != "0") {
              this.areaCode = response.data.proCode;
              this.area = response.data.pro;
            }
            this.axios
              .get("http://112.74.104.232:8080/homepage/weather", {
                params: {
                  city: this.areaCode,
                },
              })
              .then((response) => {
                this.weather = weather(response.data.lives[0].weather);
                this.weathername = response.data.lives[0].weather;
                this.temperature = response.data.lives[0].temperature;
                this.winddirection = response.data.lives[0].winddirection;
                this.windpower = response.data.lives[0].windpower;
                this.humidity = response.data.lives[0].humidity;
                this.reporttime = response.data.lives[0].reporttime;
              });
          });
      }
    },
    //选择城市
    handleSelect(item) {
      this.areaCode = item.areaCode;
      this.area = item.value;
      this.axios
        .get("http://112.74.104.232:8080/homepage/weather", {
          params: {
            city: this.areaCode,
          },
        })
        .then((response) => {
          this.weather = weather(response.data.lives[0].weather);
          this.weathername = response.data.lives[0].weather;
          this.temperature = response.data.lives[0].temperature;
          this.winddirection = response.data.lives[0].winddirection;
          this.windpower = response.data.lives[0].windpower;
          this.humidity = response.data.lives[0].humidity;
          this.reporttime = response.data.lives[0].reporttime;
          this.$notify({
            title: "成功",
            message: "地区选择成功",
            type: "success",
            showClose: false,
          });
        })
        .catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "天气获取错误",
            showClose: false,
          });
        });
    },
    //处理手动输入地址选择情况
    querySearch(queryString, cb) {
      let results = []; //筛选列表
      let a = Object.keys(this.areaCodeList); //全部地区列表
      for (let i = 0; i < a.length; i++) {
        if (a[i].indexOf(queryString) != -1) {
          let item = {}; //列表元素
          item["value"] = a[i];
          item["areaCode"] = this.areaCodeList[a[i]];
          results.push(item);
        }
      }
      cb(results);
    },
  },
  created() {
    this.value = this.auto;
    //调用阿里接口获取网络时间
    this.axios
      .get("http://112.74.104.232:8080/homepage/time")
      .then((result) => {
        this.msec = Number(result.data.data.t);
      });
    // 调用网络接口通过ip获取当位置
    if (this.auto == true) {
      this.axios
        .get("http://112.74.104.232:8080/homepage/area")
        .then((response) => {
          if (response.data.regionCode != "0") {
            this.areaCode = response.data.regionCode;
            this.area = response.data.region;
          } else if (response.data.cityCode != "0") {
            this.areaCode = response.data.cityCode;
            this.area = response.data.city;
          } else if (response.data.proCode != "0") {
            this.areaCode = response.data.proCode;
            this.area = response.data.pro;
          }
          this.axios
            .get("http://112.74.104.232:8080/homepage/weather", {
              params: {
                city: this.areaCode,
              },
            })
            .then((response) => {
              this.weather = weather(response.data.lives[0].weather);
              this.weathername = response.data.lives[0].weather;
              this.temperature = response.data.lives[0].temperature;
              this.winddirection = response.data.lives[0].winddirection;
              this.windpower = response.data.lives[0].windpower;
              this.humidity = response.data.lives[0].humidity;
              this.reporttime = response.data.lives[0].reporttime;
            });
        })
        .catch((error) => {
          this.areaCode = "110000";
          this.area = "北京市";
          this.axios
            .get("http://112.74.104.232:8080/homepage/weather", {
              params: {
                city: "110000",
              },
            })
            .then((response) => {
              this.weather = weather(response.data.lives[0].weather);
              this.weathername = response.data.lives[0].weather;
              this.temperature = response.data.lives[0].temperature;
              this.winddirection = response.data.lives[0].winddirection;
              this.windpower = response.data.lives[0].windpower;
              this.humidity = response.data.lives[0].humidity;
              this.reporttime = response.data.lives[0].reporttime;
            });
        });
    } else {
      this.areaCode = "110000";
      this.area = "北京市";
      this.axios
        .get("http://112.74.104.232:8080/homepage/weather", {
          params: {
            city: this.areaCode,
          },
        })
        .then((response) => {
          this.weather = weather(response.data.lives[0].weather);
          this.weathername = response.data.lives[0].weather;
          this.temperature = response.data.lives[0].temperature;
          this.winddirection = response.data.lives[0].winddirection;
          this.windpower = response.data.lives[0].windpower;
          this.humidity = response.data.lives[0].humidity;
          this.reporttime = response.data.lives[0].reporttime;
        });
    }

    //读取地区编码
    this.axios.get("/json/areacode.json").then((response) => {
      this.areaCodeList = response.data;
    });
    // 计时器
    setInterval(() => {
      this.msec += 1000;
    }, 1000);
  },
  computed: {
    time() {
      let h, m;
      let date = new Date(this.msec);
      if (date.getHours() < 10) {
        h = "0" + date.getHours();
      } else {
        h = date.getHours();
      }
      if (date.getMinutes() < 10) {
        m = "0" + date.getMinutes();
      } else {
        m = date.getMinutes();
      }
      return h + " : " + m;
    },
    date() {
      let day;
      let date = new Date(this.msec);
      switch (date.getDay()) {
        case 0:
          day = "日";
          break;
        case 1:
          day = "一";
          break;
        case 2:
          day = "二";
          break;
        case 3:
          day = "三";
          break;
        case 4:
          day = "四";
          break;
        case 5:
          day = "五";
          break;
        case 6:
          day = "六";
          break;
      }
      return date.getMonth() + 1 + "月" + date.getDate() + "日 星期" + day;
    },
  },
};
</script>

<style scoped>
#date {
  width: 300px;
  height: 130px;
  background: rgba(256, 256, 256, 0.6);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.left {
  /* width:200px;
    height:200px; */
  display: flex;
  flex-direction: column;
  /* justify-content:center; */
  align-items: flex-end;
}
.time {
  font-size: 60px;
  font-weight: 500;
}
.right {
  display: flex;
  width: 100px;
  height: 120px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid; */
}
.line {
  height: 120px;
  width: 0px;
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 2px;
}
.area {
  height: 20px;
}
.weather {
  width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img {
  width: 50px;
  height: 50px;
}
.temperature {
  font-size: 20px;
  margin-top: 5px;
}

/* 图标样式 */
.icon {
  width: 50px;
  height: 50px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>