<template>
  <div id="about">
    <div class="title">
      <h3 align="center" v-show="title_center">关于</h3>
      <h3 align="center" v-show="!title_center">极简起始页</h3>
      <i class="el-icon-close close" @click="close"></i>
    </div>
    <div class="main" @scroll="scroll">
      <div style="height: 66px">
        <transition name="el-fade-in">
          <div v-show="intro">
            <h2 style="text-align: center">极简起始页</h2>
            <p style="text-align: center">一个简单的起始界面</p>
            <p style="text-align: center">Author by 雪花爱啤酒</p>
          </div>
        </transition>
      </div>
      <h4>当前版本</h4>
      <div style="width: 455px">
        <el-card shadow="always">
          <h5>V1.2021.12.03</h5>
        </el-card>
      </div>
      <h4>第三方API</h4>
      <div style="width: 455px">
        <el-card shadow="always">
          <h6>地址获取</h6>
          <p>
            <a href="https://whois.pconline.com.cn/"
              >https://whois.pconline.com.cn/</a
            >
          </p>
          <br />
          <h6>时间获取</h6>
          <p>
            <a href="http://api.m.taobao.com/">http://api.m.taobao.com/</a>
          </p>
          <br />
          <h6>天气信息</h6>
          <p>
            <a href="https://restapi.amap.com/">https://restapi.amap.com/</a>
          </p>
          <br />
          <h6>一言</h6>
          <p>
            <a href="https://v1.hitokoto.cn/">https://v1.hitokoto.cn/</a>
          </p>
          <br />
          <h6>必应壁纸</h6>
          <p>
            <a href="https://cn.bing.com/">https://cn.bing.com/</a>
          </p>
        </el-card>
      </div>
      <h4>更新日志</h4>
      <div style="width: 455px">
        <el-card shadow="always">
          <el-timeline>
            <el-timeline-item timestamp="2021/12/06" placement="top">
              <el-card>
                <h4>大版本更新</h4>
                <p>最先发布的还是功能太简单了（其实是我菜(￣▽￣)"）</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2021/12/03" placement="top">
              <el-card>
                <h4>ICP备案通过</h4>
                <p>ICP备案还是挺简单的^_^</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2021/11/26" placement="top">
              <el-card>
                <h4>成功上线</h4>
                <p>部署太难了，写好项目，部署快崩溃了！</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2021/11/14" placement="top">
              <el-card>
                <h4>创建项目</h4>
                <p>产生了写一个起始页的想法</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title_center: true,
      intro: true,
    };
  },
  methods: {
    close() {
      this.$emit("setting-close");
    },
    scroll(event) {
      if (event.target.scrollTop < 40) {
        this.title_center = true;
        this.intro = true;
      } else {
        this.title_center = false;
        this.intro = false;
      }
    },
  },
};
</script>
<style scoped>
.upload_img {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.upload_img_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 120px;
  height: 80px;
  background: #f2f2f2;
  border-radius: 15px;
}
.upload_img_right {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upload_img_left i {
  font-size: 40px;
}
#about {
  width: 490px;
  height: 570px;
  background: #f5f5f5;
  border-radius: 25px;
}
.main {
  padding-left: 15px;
  overflow: auto;
  width: 470px;
  height: 500px;
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.close {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  font-size: 25px;
  position: absolute;
  right: 20px;
}
h4 {
  margin-bottom: 10px;
  margin-top: 10px;
}
p {
  color: #949494;
  font-size: 12px;
}
.close:hover {
  color: #e10b2b;
  background: rgba(222, 222, 222, 0.6);
}
a {
  color: #949494;
}
.main::-webkit-scrollbar {
  /*滚动条整体样式*/

  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
}

.main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/

  border-radius: 10px;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  background: #535353;
}

.main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  border-radius: 10px;

  /* background: #dedede; */
}
</style>