function weather(data) {
    switch (data) {
        case "晴":
            return "#icon-100-qing";

        case "少云":
            return "#icon-102-shaoyun";

        case "晴间多云":
            return "#icon-103-qingjianduoyun";

        case "多云":
            return "#icon-101-duoyun";

        case "阴":
            return "#icon-104-yin";

        case "有风":
            return "#icon-999-weizhi";

        case "平静":
            return "#icon-999-weizhi";

        case "微风":
            return "#icon-999-weizhi";

        case "和风":
            return "#icon-999-weizhi";

        case "清风":
            return "#icon-999-weizhi";

        case "强风/劲风":
            return "#icon-999-weizhi";

        case "疾风":
            return "#icon-999-weizhi";

        case "大风":
            return "#icon-999-weizhi";

        case "烈风":
            return "#icon-999-weizhi";

        case "风暴":
            return "#icon-999-weizhi";

        case "狂爆风":
            return "#icon-999-weizhi";

        case "飓风":
            return "#icon-999-weizhi";

        case "热带风暴":
            return "#icon-999-weizhi";

        case "霾":
            return "#icon-502-mai";

        case "中度霾":
            return "#icon-511-zhongdumai";

        case "重度霾":
            return "#icon-512-zhongdumai";

        case "阵雨":
            return "#icon-300-zhenyu";

        case "雷阵雨":
            return "#icon-302-leizhenyu";

        case "雷阵雨并伴有冰雹":
            return "#icon-304-leizhenyubanyoubingbao";

        case "小雨":
            return "#icon-305-xiaoyu";

        case "中雨":
            return "#icon-306-zhongyu";

        case "大雨":
            return "#icon-307-dayu";

        case "暴雨":
            return "#icon-310-baoyu";

        case "大暴雨":
            return "#icon-311-dabaoyu";

        case "特大暴雨":
            return "#icon-312-tedabaoyu";

        case "强阵雨":
            return "#icon-351-qiangzhenyu";

        case "强雷阵雨":
            return "#icon-303-qiangleizhenyu";

        case "极端降雨":
            return "#icon-308-jiduanjiangyu";

        case "毛毛雨/细雨":
            return "#icon-309-maomaoyu-xiyu";

        case "雨":
            return "#icon-399-yu";

        case "小雨-中雨":
            return "#icon-314-xiaodaozhongyu";

        case "中雨-大雨":
            return "#icon-315-zhongdaodayu";

        case "大雨-暴雨":
            return "#icon-410-dadaobaoxue";

        case "暴雨-大暴雨":
            return "#icon-317-baoyudaodabaoyu";

        case "大暴雨-特大暴雨":
            return "#icon-318-dabaoyudaotedabaoyu";

        case "雨雪天气":
            return "#icon-405-yuxuetianqi";

        case "雨夹雪":
            return "#icon-404-yujiaxue";

        case "阵雨夹雪":
            return "#icon-406-zhenyujiaxue";

        case "冻雨":
            return "#icon-313-dongyu";

        case "雪":
            return "#icon-499-xue";

        case "阵雪":
            return "#icon-457-zhenxue";

        case "小雪":
            return "#icon-400-xiaoxue";

        case "中雪":
            return "#icon-401-zhongxue";

        case "大雪":
            return "#icon-402-daxue";

        case "暴雪":
            return "#icon-403-baoxue";

        case "小雪-中雪":
            return "#icon-408-xiaodaozhongxue";

        case "中雪-大雪":
            return "#icon-409-zhongdaodaxue";

        case "大雪-暴雪":
            return "#icon-410-dadaobaoxue";

        case "浮尘":
            return "#icon-504-fuchen";

        case "扬沙":
            return "#icon-503-yangsha";

        case "沙尘暴":
            return "#icon-507-shachenbao";

        case "强沙尘暴":
            return "#icon-508-qiangshachenbao";

        case "龙卷风":
            return "#icon-999-weizhi";

        case "雾":
            return "#icon-501-wu";

        case "浓雾":
            return "#icon-514dawu";

        case "强浓雾":
            return "#icon-515-teqiangnongwu";

        case "轻雾":
            return "#icon-500-baowu";

        case "大雾":
            return "#icon-514dawu";

        case "特强浓雾":
            return "#icon-515-teqiangnongwu";

        case "热":
            return "#icon-900-re";

        case "冷":
            return "#icon-901-leng";

        case "未知":
            return "#icon-999-weizhi";

    }
}
export { //很关键
   weather
   }