<template>
  <div id="setting">
    <div class="title">
      <transition name="el-fade-in">
        <h3 align="center" v-show="title_center">设置</h3>
      </transition>

      <i class="el-icon-close close" @click="close"></i>
    </div>
    <div class="main" @scroll="scroll">
      <div style="height: 43px">
        <transition name="el-fade-in">
          <h1 v-show="title_left">设置</h1>
        </transition>
      </div>

      <br />
      <h4>搜索引擎</h4>
      <div style="width: 455px">
        <el-card shadow="hover">
          <div class="se">
            <div
              class="se-item"
              :class="{ box_shadow: i == 1 }"
              @click="choose(1, 'baidu')"
            >
              <i class="el-icon-success" v-show="i == 1"></i>
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-baidu"></use>
              </svg>
            </div>
            <div
              class="se-item"
              :class="{ box_shadow: i == 2 }"
              @click="choose(2, 'google')"
            >
              <i class="el-icon-success" v-show="i == 2"></i>
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-gugesousuo"></use>
              </svg>
            </div>
            <div
              class="se-item"
              :class="{ box_shadow: i == 3 }"
              @click="choose(3, 'biying')"
            >
              <i class="el-icon-success" v-show="i == 3"></i>
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-biying"></use>
              </svg>
            </div>
          </div>
        </el-card>
      </div>
      <h4>隐私设置</h4>
      <div style="width: 455px">
        <el-card shadow="hover">
          <div class="setting-item">
            <p style="font-size: 14px; font-weight: 100">保存搜索历史</p>
            <el-switch
              v-model="value1"
              active-color="#13ce66"
              inactive-color="#ecf0f1"
              @change="switchChange('setHistory', value1)"
            >
            </el-switch>
          </div>
          <div class="setting-item">
            <p style="font-size: 14px; font-weight: 100">位置获取</p>
            <el-switch
              v-model="value2"
              active-color="#13ce66"
              inactive-color="#ecf0f1"
              @change="switchChange('setAuto_location', value2)"
            >
            </el-switch>
          </div>
          <div class="setting-item" style="padding-bottom: 0px">
            <p style="font-size: 14px; font-weight: 200">启用搜索预测</p>

            <el-switch
              v-model="value3"
              active-color="#13ce66"
              inactive-color="#ecf0f1"
              @change="switchChange('setPredictions', value3)"
            >
            </el-switch>
          </div>
        </el-card>
      </div>
      <h4>通用设置</h4>
      <div style="width: 455px">
        <el-card shadow="hover">
          <div class="setting-item">
            <p style="font-size: 14px; font-weight: 200">
              <a href="">一言</a>（搜索框下的格言）
            </p>
            <el-switch
              v-model="value4"
              active-color="#13ce66"
              inactive-color="#ecf0f1"
              @change="switchChange('setUse_yiyan', value4)"
            >
            </el-switch>
          </div>
          <el-collapse-transition>
            <div class="setting-item" v-show="value4">
              <p style="font-size: 14px; font-weight: 100">
                <i class="el-icon-caret-right"></i>句子类型
              </p>
              <el-select v-model="select_value1" size="mini">
                <el-option key="1" label="动画" value="a"></el-option>
                <el-option key="2" label="漫画" value="b"></el-option>
                <el-option key="3" label="游戏" value="c"></el-option>
                <el-option key="4" label="文学" value="d"></el-option>
                <el-option key="5" label="原创" value="e"></el-option>
                <el-option key="8" label="影视" value="h"></el-option>
                <el-option key="9" label="诗词" value="i"></el-option>
                <el-option key="10" label="网易云" value="j"></el-option>
                <el-option key="12" label="哲学" value="k"></el-option>
                <el-option key="11" label="抖机灵" value="i"></el-option
                ><el-option key="6" label="来自网络" value="f"></el-option>
                <el-option key="7" label="其他" value="g"></el-option>
              </el-select>
            </div>
          </el-collapse-transition>

          <div class="setting-item">
            <p style="font-size: 14px; font-weight: 100">
              自动更新一言（重新打开网页生效）
            </p>
            <el-switch
              v-model="value5"
              active-color="#13ce66"
              inactive-color="#ecf0f1"
              @change="!value5 && switchChange('setAuto_yiyan', 0)"
            >
            </el-switch>
          </div>
          <el-collapse-transition>
            <div
              class="setting-item"
              style="padding-bottom: 0px"
              v-show="value5"
            >
              <p style="font-size: 14px; font-weight: 100">
                <i class="el-icon-caret-right"></i>更新间隔
              </p>
              <el-select v-model="select_value2" size="mini">
                <el-option key="1" label="1" value="1">1</el-option>
                <el-option key="2" label="2" value="2">2</el-option>
                <el-option key="3" label="3" value="3">3</el-option>
                <el-option key="4" label="4" value="4">4</el-option>
                <el-option key="5" label="5" value="5">5</el-option>
              </el-select>
            </div>
          </el-collapse-transition>
        </el-card>
      </div>
      <h4>其他设置</h4>
      <div style="width: 455px">
        <el-card shadow="hover">
          <div class="setting-item" style="padding-bottom: 0px">
            <p style="font-size: 14px; font-weight: 100">搜索框占位符</p>
            <el-input placeholder="请输入内容" v-model="input" clearable>
              <el-button
                slot="append"
                icon="el-icon-edit-outline"
                @click="placeholder(input)"
              ></el-button>
            </el-input>
          </div>
        </el-card>
      </div>

      <h4>其他搜索引擎</h4>
      <div style="width: 455px">
        <el-card shadow="hover">
          <div
            class="setting-item"
            style="padding-top: 10px"
            @click="choose(4, '360')"
          >
            <p style="font-size: 14px; font-weight: 100">360搜索</p>
            <i class="el-icon-success" v-show="i == 4"></i>
          </div>
          <div
            class="setting-item"
            style="padding-bottom: 0px"
            @click="choose(5, 'sougou')"
          >
            <p style="font-size: 14px; font-weight: 100">搜狗搜索</p>
            <i class="el-icon-success" v-show="i == 5"></i>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      select_value1: this.$store.state.yiyan_type,
      select_value2: this.$store.state.auto_yiyan,
      value1: this.$store.state.history,
      // value2: this.$store.state.auto_location,
      value3: this.$store.state.predictions,
      value4: this.$store.state.use_yiyan,
      value5: this.$store.state.auto_yiyan != 0,
      input: this.$store.state.placeholder,
      title_center: false,
      title_left: true,
    };
  },

  methods: {
    ddd() {
      console.log("ddd");
    },
    close() {
      this.$emit("setting-close");
    },
    choose(i, se) {
      this.$store.commit("setSe", se);
      this.i = i;
    },
    placeholder(value) {
      this.$store.commit("setPlaceholder", value);
    },
    scroll(event) {
      if (event.target.scrollTop < 40) {
        this.title_center = false;
        this.title_left = true;
      } else {
        this.title_center = true;
        this.title_left = false;
      }
    },
    switchChange(name, value) {
      this.$store.commit(name, value);
    },
  },
  computed: {
    value2: {
      get: function () {
        return this.$store.state.auto_location;
      },
      set: function (value) {
        this.$store.commit("setAuto_location", value);
      },
    },
    i: {
      get: function () {
        if (this.$store.state.se == "baidu") {
          return 1;
        } else if (this.$store.state.se == "google") {
          return 2;
        } else if (this.$store.state.se == "sougou") {
          return 5;
        } else if (this.$store.state.se == "biying") {
          return 3;
        } else if (this.$store.state.se == "360") {
          return 4;
        }
      },
      set: function (value){
        
      }
    },
  },
};
</script>

<style scoped>
.setting-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}
.se {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
}
.se-item i {
  position: absolute;
  color: #67c23a;
  right: 5px;
  top: 5px;
}
.setting-item i {
  color: #67c23a;
}
.se-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 85px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
}
.box_shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
#setting {
  width: 490px;
  height: 570px;
  background: #f5f5f5;
  border-radius: 25px;
}
.main {
  padding-left: 15px;
  overflow: auto;
  width: 470px;
  height: 500px;
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.close {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  font-size: 25px;
  position: absolute;
  right: 20px;
}
.close:hover {
  color: #e10b2b;
  background: rgba(222, 222, 222, 0.6);
}
.icon {
  width: 100px;
  height: 60px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
h4 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.main::-webkit-scrollbar {
  /*滚动条整体样式*/

  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
}

.main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/

  border-radius: 10px;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  background: #535353;
}

.main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  border-radius: 10px;

  /* background: #dedede; */
}
</style>