<template>
  <div id="app" :style="{ backgroundImage: url }" @click="bbb">
    <!-- 遮盖 -->
    <div class="veil" v-show="veil_show"></div>
    <!-- 第一页 -->
    <!-- 设置 -->
    <div class="setting-area">
      <i class="el-icon-s-tools setting-button" @click.stop="aaa"></i>
      <el-collapse-transition>
        <div class="setting-list" v-show="show_setting">
          <p @click="ccc(0)">设置</p>
          <p @click="ccc(1)">背景更换</p>
          <p @click="ccc(2)">反馈</p>
          <p @click="ccc(3)">关于</p>
        </div>
      </el-collapse-transition>
    </div>
    <!-- 日期天气组件 -->
    <div class="date">
      <Date :auto="this.$store.state.auto_location" />
    </div>
    <!-- 输入组件 -->
    <div class="input">
      <Input
        @input-focus="out"
        @input-null="cx"
        :se="this.$store.state.se"
        :placeholder="this.$store.state.placeholder"
        :predictions="this.$store.state.predictions"
        :history="this.$store.state.history"
      />
    </div>
    <!-- 布局空div -->
    <div style="flex: 1"></div>
    <!-- 一言 -->
    <div
      v-show="this.$store.state.use_yiyan && isShow"
      class="aphorism"
      :class="{
        animate__bounceOutRight: animate1,
        animate__fadeInRightBig: animate2,
      }"
      @animationend="
        animate1 = false;
        animate2 = false;
      "
    >
      <Aphorism
        :minute="this.$store.state.auto_yiyan"
        :type="this.$store.state.yiyan_type"
      />
    </div>
    <!-- 布局空div -->
    <div style="flex: 1"></div>

    <!-- 设置面板 -->
    <div
      class="setting"
      v-show="setting_panel"
      :class="{ animate__flipInY: animate3, animate__fadeOutDownBig: animate4 }"
      @animationend="animate3 = false"
    >
      <Setting @setting-close="ddd" v-show="this.setting_list_clik == 0" />
      <Background @setting-close="ddd" v-show="this.setting_list_clik == 1" />
      <Feedback @setting-close="ddd" v-show="this.setting_list_clik == 2" />
      <About @setting-close="ddd" v-show="this.setting_list_clik == 3" />
    </div>

    <!-- 壁纸详情 -->
    <el-popover
      placement="top"
      width="200"
      trigger="hover"
      visible-arrow="true"
    >
      <div
        style="
          height: 80px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <p class="location">{{ location }}</p>
        <p class="copyrighter">{{ copyrighter }}</p>
      </div>
      <div
        class="wallpaper"
        slot="reference"
        v-show="this.$store.state.wallpaperoption == 8"
      >
        <div class="details">
          <i class="el-icon-camera-solid"></i>
          信息
        </div>
        <div class="up" @click="up">
          <i class="el-icon-arrow-left"> </i>
        </div>
        <div class="next" @click="next">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </el-popover>
    <!-- 第一页到此为止 -->
    <!-- 底部信息 -->
    <div class="foot">
      <p>
        ©2021 许海峰 |
        <a href="https://beian.miit.gov.cn" target="blank"
          >豫ICP备2021035168号-1</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import Date from "./components/Date.vue";
import Input from "./components/Input.vue";
import Aphorism from "./components/Aphorism.vue";
import Setting from "./components/Setting.vue";
import Background from "./components/BackgroundS.vue";
import Feedback from "./components/Feedback.vue";
import About from "./components/About.vue";
export default {
  name: "App",
  data() {
    return {
      se: "baidu",
      i: 0, //当前第几张图片
      copyright: "", //壁纸版权
      show_setting: false, //设置列表展开动画
      animate1: false, //一言离开动画
      animate2: false, //一言展示动画
      animate3: false, //设置面板显示动画
      animate4: false, //设置面板消失画面
      isShow: true, //是否展示一言
      veil_show: false, //背景阴影
      setting_panel: false, //设置面板
      setting_list_clik: 0, //展开哪一个设置面板
    };
  },
  beforeCreate: function () {
    // this.Util.setStorage("12312", "213213")
    // console.log(this.Util.getStorage("111"));
    if (this.$store.state.wallpaperoption == 8)
      this.axios
        .get("http://112.74.104.232:8080/homepage/biying")
        .then((response) => {
          let imges = response.data.images;
          this.$store.commit("setWallpaperurl", imges[0].url);
          console.log(imges[0].url);
          this.$store.commit("setBiying_wallpaper_url", imges);
          this.copyright = imges[0].copyright;
        });
  },
  components: {
    Date,
    Input,
    Aphorism,
    Setting,
    Background,
    Feedback,
    About,
  },
  methods: {
    //关闭设置列表
    bbb() {
      this.show_setting = false;
    },
    //展示设置列表
    aaa() {
      this.show_setting = true;
    },
    //展示设置面板
    ccc(i) {
      this.setting_list_clik = i;
      this.veil_show = true;
      this.setting_panel = true;
      this.animate3 = true;
    },
    //关闭设置面板
    ddd() {
      this.animate4 = true;
      setTimeout(() => {
        this.setting_panel = false;
        this.veil_show = false;
        this.animate4 = false;
      }, 500);
    },
    //更换壁纸
    //上一张
    next() {
      if (this.i > 0) {
        this.i--;
        this.url = this.$store.state.biying_wallpaper_url[this.i].url;
        this.copyright = this.$store.state.biying_wallpaper_url[this.i].copyright;
      } else {
        this.$notify({
          title: "提示",
          type: "success",
          message: "已经是最后一张",
          offset: 100,
          duration: 1500,
          showClose: false,
        });
      }
    },
    //下一张
    up() {
      if (this.i < this.$store.state.biying_wallpaper_url.length - 1) {
        this.i++;
        this.url = this.$store.state.biying_wallpaper_url[this.i].url;
        this.copyright = this.$store.state.biying_wallpaper_url[this.i].copyright;
      } else {
        this.$notify({
          title: "提示",
          type: "success",
          message: "已经是第一张",
          offset: 100,
          duration: 1500,
          showClose: false,
        });
      }
    },
    //清除一言
    out() {
      this.animate1 = true;
      setTimeout(() => {
        this.isShow = false;
      }, 500);
    },
    //显示一言
    cx() {
      this.isShow = true;
      this.animate2 = true;
    },
  },
  computed: {
    location() {
      let index = this.copyright.indexOf("©");
      let str = this.copyright.substr(0, index);
      return str.substr(0, str.length - 1);
    },
    copyrighter() {
      let index = this.copyright.indexOf("©");
      let str = this.copyright.substr(index);
      return str.substr(0, str.length - 1);
    },
    //使用计算属性的原因是前缀不同
    url: {
      get: function () {
        if (this.$store.state.wallpaperoption == 8) {
          // console.log("llllllllllllll");
          return "url(https://www.bing.com/" + this.$store.state.wallpaperurl;
        } else {
          return "url(" + this.$store.state.wallpaperurl;
        }
      },
      set: function (value) {
        this.$store.commit("setWallpaperurl", value);
      },
    },
  },
};
</script>

<style scoped >
.veil {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 98;
}
#app {
  position: 0;
  /* 更换背景动态绑定 */
  /* background-image: url(https://www.bing.com//th?id=OHR.HyacinthMacaws_ZH-CN1191345036_1920x1080.jpg&rf=LaDigue_1920x1080.jpg&pid=hp); */
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  /* -------------------- */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.date {
  margin-top: 70px;
}
.input {
  margin-top: 70px;
}
.aphorism {
  /* animation-fill-mode: forwards; */
  animation-duration: 0.6s;
}
.foot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  position: absolute;
  bottom: 10px;
  background: rgba(256, 256, 256, 0.6);
}
.setting {
  position: absolute;
  z-index: 99;
  animation-duration: 0.8s;
}
.wallpaper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 60px;
  right: 160px;
}
.details {
  border-radius: 10px;
  background: rgba(256, 256, 256, 0.6);
  padding: 5px;
}
.up {
  padding: 5px;
  margin-left: 10px;
  background: rgba(256, 256, 256, 0.6);
  border-radius: 10px;
}
.next {
  padding: 5px;
  margin-left: 10px;
  background: rgba(256, 256, 256, 0.6);
  border-radius: 10px;
}
.copyrighter {
  font-size: 10px;
  font-weight: 100;
  margin-top: 10px;
}
.location {
  font-weight: bold;
}
.setting-area {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100px;
  height: 150px;
  position: absolute;
  top: 20px;
  right: 30px;
}
.setting-button {
  cursor: pointer;
  font-size: 20px;
  color: #dedede;
}
.setting-list {
  overflow: auto;
  background: #fff;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  align-items: center;
}
.setting-list p {
  font-weight: 100;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
.setting-list p:hover {
  background: #dedede;
}
.setting-button:hover {
  animation: setting-button1 1s;
  animation-fill-mode: forwards;
}
.foot a {
  text-decoration: none;
  color: #000;
}
@keyframes setting-button1 {
  0% {
  }
  100% {
    transform: rotate(120deg);
    color: #fff;
  }
}
</style>
