<template>
  <div
    id="aphorism"
    @click="change"
    :class="{ animate__flipInX: animation }"
    @animationend="animation = false"
  >
    <!-- <p class="title">壹句</p> -->
    <p class="aphorism"><i class="el-icon-edit-outline"></i> {{ hitokoto }}</p>
    <p class="from">
      <i class="el-icon-s-promotion"></i>《{{ from }}》{{ from_who }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animation: false,
      hitokoto: "",
      from: "",
      from_who: "",
    };
  },
  props: {
    type: String,
    minute: Number,
  },
  created() {
    this.axios
      .get("http://112.74.104.232:8080/homepage/aphorism")
      .then((response) => {
        this.hitokoto = response.data.hitokoto;
        this.from = response.data.from;
        this.from_who = response.data.from_who;
      });
    if (this.minute != 0) {
      setInterval(() => {
        this.axios
          .get("http://112.74.104.232:8080/homepage/aphorism")
          .then((response) => {
            this.hitokoto = response.data.hitokoto;
            this.from = response.data.from;
            this.from_who = response.data.from_who;
          });
      }, this.minute * 60 * 1000);
    }
  },
  methods: {
    change() {
      this.animation = true;
      this.axios
        .get("http://112.74.104.232:8080/homepage/aphorism")
        .then((response) => {
          this.hitokoto = response.data.hitokoto;
          this.from = response.data.from;
          this.from_who = response.data.from_who;
        });
    },
  },
};
</script>

<style scoped>
#aphorism {
  background: rgba(256, 256, 256, 0.4);
  border-radius: 20px;
  width: 600px;
  display: flex;

  flex-direction: column;
  animation-duration: 1s;
}
.title {
  font-size: 22px;
  font-weight: 400;
}
.aphorism {
  line-height: 40px;
  font-family: "楷体";
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.from {
  padding-right: 10px;
  line-height: 30px;
  text-align: right;
  font-weight: 600;
}
</style>