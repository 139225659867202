import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        se: "baidu", //搜索引擎选项 类型：String
        history: false, //搜索历史 类型：Boolean
        auto_location: true, // 自动获取位置 类型：Boolean
        predictions: true, //搜索预测 类型：Boolean
        use_yiyan: true, //开启一言 类型：Boolean
        yiyan_type: "c", //一言语句类型 类型：String
        auto_yiyan: 0, //自动更新一言间隔，其中0代表不自动更新 类型：Number
        placeholder: "一川烟草，满城飞絮，梅子黄时雨。", //搜索框占位符 类型：String
        wallpaperoption: 8, //选择壁纸类型，8代表默认选择biying壁纸 类型：Number 
        wallpaperurl: "", //壁纸位置 类型：String
        biying_wallpaper_url: [] //8张必应壁纸 类型：Array
    },
    mutations: {
        setSe(state, se) {
            state.se = se;
        },
        setHistory(state, history) {
            state.history = history;
        },
        setAuto_location(state, auto_location) {
            state.auto_location = auto_location
        },
        setPredictions(state, predictions) {
            state.predictions = predictions
        },
        setUse_yiyan(state, use_yiyan) {
            state.use_yiyan = use_yiyan
        },
        setYiyan_type(state, yiyan_type) {
            state.yiyan_type = yiyan_type
        },
        setAuto_yiyan(state, auto_yiyan) {
            state.auto_yiyan = auto_yiyan
        },
        setPlaceholder(state, placeholder) {
            state.placeholder = placeholder
        },
        setWallpaperoption(state, wallpaperoption) {
            state.wallpaperoption = wallpaperoption
        },
        setWallpaperurl(state, wallpaperurl) {
            state.wallpaperurl = wallpaperurl
        },
        setBiying_wallpaper_url(state, biying_wallpaper_url){
            state.biying_wallpaper_url = biying_wallpaper_url
        }
    }
})
export default store